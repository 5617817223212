.jeton .pHeader {
  border-bottom: 6px solid #ffb96a;
}

.jeton .listedOption .item input[type='radio']:checked ~ label:before {
  border-color: #ff6300;
}

.jeton .listedOption .item label:after {
  background: #ff6300;
}

.jeton .pButton .btn-primary {
  background: linear-gradient(69.15deg, #ff6300 13.79%, #ff6300 86.21%);
}
.jeton .logo {
  display: none;
}

@media (min-width: 768px) {
  .jeton .logo {
    display: block;
    text-align: right;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .jeton .logo img {
    width: 140px;
  }
}
