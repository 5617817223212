.angelspay .pHeader {
  border-bottom: 6px solid #470687;
}

.angelspay .logo {
  display: none;
}

.angelspay .logo img {
  height: 35px;
}

@media (min-width: 768px) {
  .angelspay .logo {
    display: block;
    text-align: right;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .angelspay .pButton .btn-primary {
    background: linear-gradient(69.15deg, #e76900 13.79%, #e76900 86.21%);
  }
}
