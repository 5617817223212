.classicpay .pHeader {
  background: #261132;
  background-image: none;
  border-bottom: 6px solid #e0ca3c;
}

.classicpay .listedOption .item input[type='radio']:checked ~ label:before {
  border-color: #261132;
}

.classicpay .listedOption .item label:after {
  background: #261132;
}

.classicpay .pButton .btn-primary {
  background: linear-gradient(69.15deg, #3e2f5b 13.79%, #3e2f5b 86.21%);
}
