.easypaycircle .pHeader {
  background: #29335c;
  background-image: none;
  border-bottom: 6px solid #f3a712;
}

.easypaycircle .listedOption .item input[type='radio']:checked ~ label:before {
  border-color: #db2b39;
}

.easypaycircle .listedOption .item label:after {
  background: #db2b39;
}

.easypaycircle .pButton .btn-primary {
  background: linear-gradient(69.15deg, #db2b39 13.79%, #db2b39 86.21%);
}
