.tkbprocessing .pHeader {
  border-bottom: 6px solid #ee4746;
}

.tkbprocessing .logo {
  display: none;
}

.tkbprocessing .logo img {
  height: 50px;
}

@media (min-width: 768px) {
  .tkbprocessing .logo {
    display: block;
    text-align: right;
    margin-bottom: 20px;
    margin-top: 20px;
  }
}
